import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/style.css'
createApp(App).use(store).use(router).mount('#app')
 
router.beforeEach((to, from, next) => {
  document.title = ` ${process.env.VUE_APP_TITLE} - ${to.name} `
  next()
})

document.addEventListener("DOMContentLoaded", function(){
    checkResolution()
    //MENU SHOW AND HIDE
    let menu = document.querySelector('#cell_menu');
    let showMenu = document.getElementById('cell_menu_content');
    let getItems = document.querySelectorAll('nav.cell-menu-content ul li a');
    let close = document.getElementById('cell_menu_close');
    if (window.matchMedia("(min-width: 300px)").matches && window.matchMedia("(max-width: 480px)").matches || window.matchMedia("(min-width: 481px)").matches && window.matchMedia("(max-width: 768px)").matches){ 
      menu.addEventListener('click',function(){
        showMenu.classList.add('cell-menu-show-mobile')
        showMenu.classList.remove('cell-menu-hide')
      })
      getItems.forEach(itemMenu => {
        itemMenu.addEventListener('click', ()=>{
          showMenu.classList.add('cell-menu-hide')
          showMenu.classList.remove('cell-menu-show-mobile') 
        })
      });
      close.addEventListener('click',()=>{ 
        showMenu.classList.add('cell-menu-hide')
        showMenu.classList.remove('cell-menu-show-mobile') 
      })
    }else{
      menu.addEventListener('click',()=>{
        showMenu.classList.add('cell-menu-show')
        showMenu.classList.remove('cell-menu-hide')
      })
      getItems.forEach(itemMenu => {
        itemMenu.addEventListener('click', ()=>{
          showMenu.classList.add('cell-menu-hide')
          showMenu.classList.remove('cell-menu-show')
        })
      });
      close.addEventListener('click',function(){ 
        showMenu.classList.add('cell-menu-hide')
        showMenu.classList.remove('cell-menu-show') 
      })
    }

    //CAROUSEL IMAGE
    let slideIndex = 1;
    if (slideIndex == 1) {
        showSlides(slideIndex);
    }

    setInterval(function () {
        showSlides(slideIndex);
        slideIndex++;
    }, 4000)
    
    function showSlides(n) {
        var slides = document.getElementsByClassName("item");
        if (n > slides.length) {
            slideIndex = 1
        }
        if (n < 1) {
            slideIndex = slides.length
        }
        for (let i = 0; i < slides.length; i++) {
            slides[i].style.display = "none";
        }
        slides[slideIndex - 1].style.display = "block";
    }
})


function checkResolution(){
    console.log("Your Resolution is: " + screen.width + " x " + screen.height) 
    if (window.matchMedia("(min-width: 300px)").matches && window.matchMedia("(max-width: 480px)").matches){ 
      console.log('Match in 300px')
    }else
    if (window.matchMedia("(min-width: 481px)").matches && window.matchMedia("(max-width: 768px)").matches){
      console.log('Match in 768px')
    }else
    if (window.matchMedia("(min-width: 769px)").matches && window.matchMedia("(max-width: 996px)").matches){
      console.log('Match in 996px')
    }else
    if (window.matchMedia("(min-width: 996px)").matches && window.matchMedia("(max-width: 1024px)").matches){
      console.log('Match in 1024px')
    }else
    if (window.matchMedia("(min-width: 1024px)").matches && window.matchMedia("(max-width: 1200px)").matches){
      console.log('Match in 1200x')
    }else
    if (window.matchMedia("(min-width: 1201px)").matches && window.matchMedia("(max-width: 1919px)").matches){
      console.log('Match most 1201px')
    }
}
 
