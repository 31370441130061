import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import VueRouterMetaTags from '@bachdgvn/vue-router-meta-tags';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      metaTags: [
        { property: 'og:title', 
          content: "serviciostelefonica - home"
        },
        { 
          name: 'description',
          content: `seviciostelefonica technology product sales website`,
        },
        {
          property: 'og:description',
          content: `seviciostelefonica technology product sales website`,
        },
        {
          property: 'og:type', 
          content: 'website'
        },
        {
          property: 'og:url', 
          content: `https://${process.env.VUE_APP_TITLE}.com/`
        },
        {
          property: 'og:image', 
          content: `https://${process.env.VUE_APP_TITLE}.com/icon_url.jpg`
        },
        {
          property: 'og:site-name', 
          content: `${process.env.VUE_APP_TITLE}` 
        },
        {
          property: 'robots', 
          content: `index` 
        },
        {
          property: 'keywords', 
          content: `serviciostelefonica, servicios, telefonica, phone, tecnology, buy cell, cell.com.pl` 
        }, 
      ],
    },
  },
  {
    path: '/products',
    name: 'products',
    component: () => import(/* webpackChunkName: "about" */ '../views/Products.vue'),
    meta: {
      metaTags: [
        { property: 'og:title', 
          content: "serviciostelefonica - products"
        },
        { 
          name: 'description',
          content: `seviciostelefonica technology product sales website`,
        },
        {
          property: 'og:description',
          content: `seviciostelefonica technology product sales website`,
        },
        {
          property: 'og:type', 
          content: 'website'
        },
        {
          property: 'og:url', 
          content: `https://${process.env.VUE_APP_TITLE}.com/products`
        },
        {
          property: 'og:image', 
          content: `https://${process.env.VUE_APP_TITLE}.com/icon_url.jpg`
        },
        {
          property: 'og:site-name', 
          content: `${process.env.VUE_APP_TITLE}` 
        },
        {
          property: 'robots', 
          content: `index` 
        },
        {
          property: 'keywords', 
          content: `serviciostelefonica, servicios, telefonica, phone, tecnology, buy cell, cell.com.pl, products` 
        }, 
      ],
    },
  },
  {
    path: '/legal',
    name: 'legal',
    component: () => import(/* webpackChunkName: "about" */ '../views/Legal.vue'),
    meta: {
      metaTags: [
        { property: 'og:title', 
          content: "serviciostelefonica - legal"
        },
        { 
          name: 'description',
          content: `seviciostelefonica technology product sales website`,
        },
        {
          property: 'og:description',
          content: `seviciostelefonica technology product sales website`,
        },
        {
          property: 'og:type', 
          content: 'website'
        },
        {
          property: 'og:url', 
          content: `https://${process.env.VUE_APP_TITLE}.com/legal`
        },
        {
          property: 'og:image', 
          content: `https://${process.env.VUE_APP_TITLE}.com/icon_url.jpg`
        },
        {
          property: 'og:site-name', 
          content: `${process.env.VUE_APP_TITLE}` 
        },
        {
          property: 'robots', 
          content: `index` 
        },
        {
          property: 'keywords', 
          content: `serviciostelefonica, servicios, telefonica, phone, tecnology, buy cell, cell.com.pl, legal` 
        }, 
      ],
    },
  },
  {
    path: '/information',
    name: 'information',
    component: () => import(/* webpackChunkName: "about" */ '../views/Information.vue'),
    meta: {
      metaTags: [
        { property: 'og:title', 
          content: "serviciostelefonica - information"
        },
        { 
          name: 'description',
          content: `seviciostelefonica technology product sales website`,
        },
        {
          property: 'og:description',
          content: `seviciostelefonica technology product sales website`,
        },
        {
          property: 'og:type', 
          content: 'website'
        },
        {
          property: 'og:url', 
          content: `https://${process.env.VUE_APP_TITLE}.com/information`
        },
        {
          property: 'og:image', 
          content: `https://${process.env.VUE_APP_TITLE}.com/icon_url.jpg`
        },
        {
          property: 'og:site-name', 
          content: `${process.env.VUE_APP_TITLE}` 
        },
        {
          property: 'robots', 
          content: `index` 
        },
        {
          property: 'keywords', 
          content: `serviciostelefonica, servicios, telefonica, phone, tecnology, buy cell, cell.com.pl, information` 
        }, 
      ],
    },
  },
  {
    path: '/contact', 
    name: 'contact',
    component: () => import(/* webpackChunkName: "about" */ '../views/Contact.vue'),
    meta: {
      metaTags: [
        { property: 'og:title', 
          content: "serviciostelefonica - contact"
        },
        { 
          name: 'description',
          content: `seviciostelefonica technology product sales website`,
        },
        {
          property: 'og:description',
          content: `seviciostelefonica technology product sales website`,
        },
        {
          property: 'og:type', 
          content: 'website'
        },
        {
          property: 'og:url', 
          content: `${process.env.VUE_BASE_URL}/contact`
        },
        {
          property: 'og:image', 
          content: `${process.env.VUE_BASE_URL}/icon_url.jpg`
        },
        {
          property: 'og:site-name', 
          content: `${process.env.VUE_APP_TITLE}` 
        },
        {
          property: 'robots', 
          content: `index` 
        },
        {
          property: 'keywords', 
          content: `serviciostelefonica, servicios, telefonica, phone, tecnology, buy cell, cell.com.pl` 
        }, 
      ],
    },
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(VueRouterMetaTags.update);

export default router
