<template>
  <div>
    <div v-if="control == false">
        <div class="wizzle-row wizzle-text-center">
        <div class="wizzle-col-3">
            <div class="servicetelefonica-ads-box wizzle-position-relative">
                    <img src="#" width="300" height="300"
                     class="wizzle-pixel-height-300 wizzle-width-full">
                    <div class="wizzle-position-absolute servicetelefonica-ads-placeholder">
                        <h4>Loading ADS..</h4>
                    </div>
            </div>
        </div>
        <div class="wizzle-col-3">
            <div class="servicetelefonica-ads-box wizzle-position-relative">
                    <img src="#" width="300" height="300"
                     class="wizzle-pixel-height-300 wizzle-width-full">
                     <div class="wizzle-position-absolute servicetelefonica-ads-placeholder">
                        <h4>Loading ADS..</h4>
                    </div>
            </div>
        </div>
        <div class="wizzle-col-3">
            <div class="servicetelefonica-ads-box wizzle-position-relative">
                   <img src="#" width="300" height="300"
                     class="wizzle-pixel-height-300 wizzle-width-full">
                     <div class="wizzle-position-absolute servicetelefonica-ads-placeholder">
                        <h4>Loading ADS..</h4>
                    </div>
            </div>
        </div>
        <div class="wizzle-col-3">
            <div class="servicetelefonica-ads-box wizzle-position-relative">
                <img src="#" width="300" height="300"
                     class="wizzle-pixel-height-300 wizzle-width-full">
                     <div class="wizzle-position-absolute servicetelefonica-ads-placeholder">
                        <h4>Loading ADS..</h4>
                    </div>
            </div>
        </div>
        </div>
    </div>
    <div v-else>
        <div class="wizzle-row wizzle-text-center wizzle-margin-top-2" id="servicetelefonica_ads">
        <div v-for="(itemAds, index) in imageBase64" :key="index" class="wizzle-col-3">
            <div v-if="index < 4" class="wizzle-padding">
                <a :href="imageUrl[index]"><img :src="itemAds" width="300" height="300"
                        :alt="imageName[index]" class="wizzle-pixel-height-300 wizzle-width-full"></a>
            </div>
        </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ServiciosTelefonicaAds',
  data(){
      return({
        imageBase64: [],
        imageName: [],
        imageUrl: [],
        control: false,
      })
  },
  async beforeMount() {
    let response = await fetch('https://rank.gs/api/')
    let getReult = await response.json()
    let maxNum = 0
    let minNum = 0
    let showCount = 0
    let getNumerShow = 0
    let addAdsElements = []
    getReult.forEach((elementAds,index) => {
        if(elementAds[0].orientation == 'HORIZONTAL'){
            maxNum = index 
            addAdsElements.push({
                base64: elementAds[0].base64,
                name: elementAds[0].image_name,
                url: elementAds[0].url
            })
        }
    });
    while(showCount < 4){
        getNumerShow = Math.floor(Math.random() * ((maxNum - 1) - minNum) + minNum)
        this.imageBase64.push(addAdsElements[getNumerShow].base64)
        this.imageName.push(addAdsElements[getNumerShow].image_name)
        this.imageUrl.push(addAdsElements[getNumerShow].url)
        showCount++
    }
    this.control = true
}
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.servicetelefonica-ads-box{
    margin: 10px;
}
.servicetelefonica-ads-placeholder{
    top: 0;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    animation: background-animate infinite 3s ease-in-out;
    color: #fff;
}

 @keyframes background-animate {
     0% {
        background: #000; 
     }
     50%{
         background: #333;
     }
     100%{
         background: #000; 
     }
 }
</style>
