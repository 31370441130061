<template>
  <div>
    <!-- 
      <div class="servicetelefonica-loading" id="loading">
      <div class="loader"></div>
    </div>
    -->
    <header>
      <div class="wizzle-box-shadow">
        <div class="wizzle-row">
          <div class="wizzle-col-4">
            <div class="cell-title"><a href="/">
                <h1 class="wizzle-text-white wizzle-text-left cell-font-1">SERIVICIOS</h1>
                <h1 class="wizzle-text-white wizzle-text-left cell-font-1 cell-title-sub"><span
                    class="cell-font-3 cell-color-2">TELEFONICA</span></h1>
              </a>
            </div>
          </div>
          <div class="wizzle-col-8 wizzle-position-relative cell-menu">
            <div class="wizzle-position-absolute wizzle-left-0 wizzle-right-0">
              <h3 class="wizzle-text-white wizzle-text-right cell-menu-title"> <a id="cell_menu"><img
                  src="./assets/menu.png" alt=""><span class="cell-menu-title-format">MENU</span></a></h3>
            </div>
            <nav class="cell-menu-hide cell-menu-content" id="cell_menu_content">
              <h3 class="wizzle-text-center wizzle-text-size-30  cell-menu-name">Menu</h3>
              <ul>
                <li>
                  <router-link to="/" class="cell-font-4 wizzle-text-weight-bold">HOME</router-link>
                </li>
                <li>
                  <router-link to="/products" class="cell-font-4 wizzle-text-weight-bold">PRODUCTS</router-link>
                </li>
                <li>
                  <router-link to="/legal" class="cell-font-4 wizzle-text-weight-bold">LEGAL WARNING</router-link>
                </li>
                <li>
                  <router-link to="/information" class="cell-font-4 wizzle-text-weight-bold">INFORMATION</router-link>
                </li> 
                <li>
                  <router-link to="/contact" class="cell-font-4 wizzle-text-weight-bold">CONTACTS</router-link>
                </li>
              </ul> 
              <a id="cell_menu_close">
                <h1 class="wizzle-text-center cell-menu-close">X</h1>
              </a>
            </nav>
          </div>
        </div>
        <div class="wizzle-row cell-bg-bottom">
          <div class="wizzle-col-12 cell-bg-header">
          </div>
        </div>
      </div>
    </header>
    <router-view />
  </div>
</template>

<style>

</style>
