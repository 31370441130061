<template>
    <div class="">
            <div class="wizzle-row wizzle-position-relative">
                <div class="wizzle-col-4">
                    <div class="cell-title">
                        <h1 class="wizzle-text-white wizzle-text-left cell-font-1">SERVICIOS</h1>
                <h1 class="wizzle-text-white wizzle-text-left cell-font-1 cell-title-sub"><span
                    class="cell-font-3 cell-color-2">TELEFONICA</span></h1>
                    </div>
                </div>
                <div class="wizzle-col-8">
                    <form action="#">
                        <div class="cell-search">
                            <button type="submit"><img src="../assets/search.png" alt=""></button>
                            <input type="text" class="wizzle-text-weight-bold" placeholder="SEARCH">
                        </div>
                    </form>
                </div>
            </div>
            <div class="wizzle-row wizzle-justify-content-center">
                <div class="wizzle-col-12 wizzle-margin-bottom-2 wizzle-margin-top-2">
                    <div class="cell-title-footer">
                        <h5><router-link to="/PRODUCTS" class="cell-font-2  wizzle-text-white">PRODUCTS</router-link></h5>
                        <h5><router-link to="/legal" class="cell-font-2 wizzle-text-white">LEGAL WARNING</router-link></h5>
                        <h5><router-link to="/information" class="cell-font-2 wizzle-text-white">INFORMATION</router-link></h5>
                        <h5><router-link to="/contact" class="cell-font-2 wizzle-text-white">CONTACTS</router-link></h5> 
                    </div>
                </div>
            </div>
            <div class="wizzle-row">
                <div class="wizzle-col-12 cell-bg-color-4">
                    <p class="wizzle-text-center cell-color-8 wizzle-padding wizzle-text-weight-bold">Copyright © 2022 cell.com.pl</p>
                </div>
            </div>
        </div>
</template>

<script>
export default {
    name: 'SerivicosTelefonicaFooter'
}
</script>

<style>

</style>