<template>
  <div class="home">
    <main class=""> 
        <section class="wizzle-position-relative wizzle-zIndex-negative-1">
            <div class="wizzle-row cell-bg-color-11">
                <div class="wizzle-col-12">
                    <div class="cell-carousel">
                        <div class="item fade">
                            <h2 class="cell-font-3">Products</h2>
                            <img src="../assets/carousel_1.jpg" alt="cell main">
                        </div>
                        <div class="item fade">
                            <h2 class="cell-font-3">Eficiency</h2>
                            <img src="../assets/carousel_2.jpg" alt="cell main">
                        </div>
                        <div class="item fade">
                            <h2 class="cell-font-3">Quality</h2>
                            <img src="../assets/carousel_3.jpg" alt="cell main">
                        </div>
                        <div class="item fade"> 
                            <h2 class="cell-font-3">All Applications</h2>
                            <img src="../assets/carousel_4.jpg" alt="cell main">
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <ServiciosTelefonicaAds></ServiciosTelefonicaAds>
        </section>
    </main>
    <footer>
        <ServiciosTelefonicaFooter></ServiciosTelefonicaFooter>
    </footer>
  </div>
</template>

<script>
// @ is an alias to /src
import ServiciosTelefonicaFooter from '@/components/servicios-telefonica-footer.vue'
import ServiciosTelefonicaAds from '@/components/servicios-telefonica-ads-horizontal.vue'
export default {
  name: 'Home',
  components: {
    ServiciosTelefonicaFooter,
    ServiciosTelefonicaAds    
  } 
}
</script>

<style>

</style>

